<template>
	<div>
		<Header />
		<main class="main">
			<picture class="main__background">
				<source media="(min-width:1440px)" :srcset="require('@/assets/main-1440.png')">
				<source media="(min-width:992px)" :srcset="require('@/assets/main-992.png')">
				<source media="(min-width:768px)" :srcset="require('@/assets/main-768.png')">
				<source media="(min-width:320px)" :srcset="require('@/assets/main-320.png')">
				<img :src="require('@/assets/main-320.png')" alt="Real Stories">
			</picture>
			<OurGirls />
			<WhyUs />
		</main>
		<Info />
		<Footer />

		<modal
			name="join-modal"
			width="100%"
			height="auto"
		>
      <div class="popup">
				<svg
					class="popup__close" @click="$modal.hide('join-modal')"
					width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
					<rect width="40" height="40" rx="5" fill="#C4C4C4" fill-opacity="0.5"/>
					<path d="M27 24.6175L21.8031 19.4869L26.9281 14.3106L24.6175 12L19.485 17.1988L14.2912 12.0719L12 14.3631L17.2006 19.5131L12.0719 24.7087L14.3631 27L19.5112 21.8013L24.6894 26.9281L27 24.6175Z" fill="#F02182"/>
				</svg>
				<Info />
			</div>
		</modal>

	</div>
</template>

<script>
import Header from '@/components/Header';
import WhyUs from '@/components/WhyUs';
import Info from '@/components/Info';
import OurGirls from '@/components/OurGirls';
import Footer from '@/components/Footer';

export default {
	name: 'Home',
	components: {
		Header,
		WhyUs,
		Info,
		OurGirls,
		Footer,
	},
	created() {
		this.sendPidAndSid();
	},
	methods: {
		async sendPidAndSid() {
			if (process.env.NODE_ENV === 'development') return;
			const oSearchParams = new URL(document.location).searchParams;
			oSearchParams.set('referer', document.referrer);

			try {
				const query = await fetch('/api/?action=send_partner_info', {
					method: 'POST',
					body: oSearchParams,
				});
				const { success, error } = await query.json();
				if (success) {
					document.location = '/';
				} else if (error) {
					this.error = error;
				}
				console.log("Success send pid/sid");
			} catch(error) {
				console.log(error);
			}
		}
	}
}
</script>

<style lang="scss">

	.preloader-general {
		position: fixed;
		z-index: 999999;
		top: 0;
		left: 0;
		right: auto;
		bottom: auto;
		height: 100%;
		width: 100%;
		background-image: -webkit-linear-gradient(left, #0674fd, #00d0f9);
	}

	.main {
		position: relative;

		&__background {
			z-index: -1;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			img {
				object-fit: contain;
				height: 100%;
				object-position: top center;
			}
		}
	}

	.popup {
		position: relative;

		&__close {
			position: absolute;
			z-index: 4;

			@include xs {
				top: 10px;
				right: 10px;
			}

			@include sm {
				top: 25px;
				right: 25px;
			}

			@media (min-width: 992px) {
				top: 25px;
				right: 25px;
				width: 35px;
				height: 35px;
			}
		}

		@include sm {
			background: linear-gradient(245.43deg, #FFFFFF 37.12%, #F2F2F2 86.65%);
			border-radius: 20px;
			padding-top: 31px;
		}

		@include md {
			background: #FFFFFF;
			border-radius: 20px;
			max-width: 892px;
			width: 100%;
			margin: 38px auto;

			.info__form {
				padding-right: 70px;
			}

			.info::before {
				display: none;
			}

			.info__background::before {
				z-index: 1;
				left: -72px;
				width: calc(100% + 72px);
				bottom: -26px;
			}

			.info__background img {
				position: relative;
				z-index: 2;
				margin-left: -50px;
			}
		}

		@include lg {
			background: #FFFFFF;
			border-radius: 20px;
			max-width: 1170px;
			width: 100%;
			margin: 50px auto 30px auto;

			.info__form {
				padding-right: 70px;
				margin-top: auto;
				margin-bottom: auto;
			}

			.info::before {
				display: none;
			}

			.info__background::before {
				z-index: 1;
				left: -72px;
				width: calc(100% + 72px);
				bottom: -30px;
				top: unset;
			}

			.info__background img {
				position: relative;
				z-index: 2;
				margin-left: -50px;
				margin-top: -50px;
				height: calc(100% + 70px);
			}
		}
	}

	.vm--modal {
		background: transparent !important;
		box-shadow: none !important;
	}
</style>
